export type DetectionBox = [number, number, number, number];

export type LoadingStatus = "loading" | "done" | Error;

export const PageState = ["OCR", "LicensePlate", "VIN-number"];
export type PageStateType = typeof PageState[number];

export interface TextDetectionAndOCREvent {
  inference_result: InferenceResult;
  media?: string;
}

export interface InferenceResult {
  inference_id: string;
  boxes: DetectionBox[];
  words: string[];
  scores: number[];
}

export interface Detection {
  box: DetectionBox;
  word: string;
  score: number;
}
