import React from "react";
import { Detection, DetectionBox, TextDetectionAndOCREvent } from "../types";
import { InferenceImage } from "./InferenceImage";
import { Box, Divider } from "@wiretronic/iris";

function yInBox(y: number, box: DetectionBox) {
  const [, y1, , y2] = box;
  return y < y2 && y > y1;
}

function sameLine(a: DetectionBox, b: DetectionBox) {
  const [, ay1, , ay2] = a;
  const [, by1, , by2] = b;
  const aMeanY = (ay2 + ay1) / 2;
  const bMeanY = (by2 + by1) / 2;
  return yInBox(aMeanY, b) || yInBox(bMeanY, a);
}

function topToBottomleftToRightSort(a: Detection, b: Detection) {
  const [ax1, ay1, ,] = a.box;
  const [bx1, by1, ,] = b.box;

  if (sameLine(a.box, b.box)) return ax1 - bx1;
  return ay1 - by1;
}

export default function InferenceResultView({
  event,
  bottomDivider,
}: {
  event: TextDetectionAndOCREvent;
  bottomDivider: boolean;
}) {
  const imageURL = event.media
    ? `https://blob.wirevision.com/api/v2/get-signed-url?name=${event.media.split("/").slice(5).join("/")}`
    : `https://blob.wirevision.com/api/v1/blobs/${event.inference_result.inference_id}.JPEG`;

  const scoreThreshold: number = 0.3;

  const inferenceResults = event.inference_result;
  const detections = inferenceResults.scores
    .map((_, i) => ({
      score: inferenceResults.scores[i],
      box: inferenceResults.boxes[i],
      word: inferenceResults.words[i],
    }))
    .filter((d) => d.score > scoreThreshold)
    .sort(topToBottomleftToRightSort);

  return (
    <Box id={`inference-result-view-${event.inference_result.inference_id}`} pb={8}>
      <InferenceImage detections={detections} imageURL={imageURL} />
      {bottomDivider ? <Divider /> : <></>}
    </Box>
  );
}
