import React, { useContext, useEffect, useState } from "react";
import InferenceResultView from "../modules/InferenceResult";
import { LoadingStatus, TextDetectionAndOCREvent } from "../types";
import jwtDecode from "jwt-decode";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import {
  loadLicensePlateEvent,
  loadTextDetectionAndOCREvents,
  remapLicensePlateEventToOCREventInferenceResults,
} from "../lib/BackendConnection";
import { Box } from "@wiretronic/iris";
import { UserContext } from "../lib/UserContext";
import HelperMessage from "../lib/components/HelperMessage";
import { getLicensePlate } from "../text/licensePlate";

export default function LicenseplatePage() {
  const [events, setEvents] = useState<TextDetectionAndOCREvent[]>([]);
  const [levents, setLevents] = useState<TextDetectionAndOCREvent[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("loading");
  const context = useContext(UserContext);

  const [licensePlate, setLicensePlate] = useState<TextDetectionAndOCREvent | null>(null);

  const eventLimit = 1;

  const updatedTextLicenceplateEvent = (licenseplateEvent: TextDetectionAndOCREvent): TextDetectionAndOCREvent => {
    if (licenseplateEvent && licenseplateEvent.inference_result.boxes.length === 0) {
      return licenseplateEvent;
    }

    for (let i = 0; i < licenseplateEvent.inference_result.boxes.length; i++) {
      licenseplateEvent.inference_result.words[i] = "";
      const platenumbers = events
        .map((event) => getLicensePlate(event.inference_result, licenseplateEvent.inference_result.boxes[i]))
        .filter((str) => str !== undefined);

      if (platenumbers.length > 0) licenseplateEvent.inference_result.words[i] = platenumbers[0] ?? "";
    }
    return licenseplateEvent;
  };

  const addEvent = (_event: TextDetectionAndOCREvent) => {
    setEvents([_event, ...events].slice(0, eventLimit));
  };
  const addLevent = (_levent: TextDetectionAndOCREvent) => {
    setLevents([...remapLicensePlateEventToOCREventInferenceResults([_levent]), ...levents].slice(0, eventLimit));
  };

  const loadEvents = async (abortController: AbortController) => {
    if (context.token === null) return;
    const userId = (jwtDecode(context.token) as any)["userId"];

    try {
      const licensePlateEvents = await loadLicensePlateEvent(userId, context.token, 1);
      setLevents(licensePlateEvents);

      fetchEventSource(
        new URL(
          `/api/v2/user-events/sse?userId=${userId}&origin=wirevision-inference&type=license-plate`,
          process.env.REACT_APP_EVENT_BASE_URL
        ).toString(),
        {
          headers: { Authorization: `Bearer ${context.token}` },
          onerror: console.error,
          onmessage: (message) => addLevent(JSON.parse(message.data)),
          signal: abortController.signal,
        }
      );

      const textDetectionEvents = await loadTextDetectionAndOCREvents(userId, context.token, eventLimit);
      setEvents(textDetectionEvents);
      fetchEventSource(
        new URL(
          `/api/v2/user-events/sse?userId=${userId}&origin=wirevision-inference&type=text_detection_and_ocr`,
          process.env.REACT_APP_EVENT_BASE_URL
        ).toString(),
        {
          headers: { Authorization: `Bearer ${context.token}` },
          onerror: console.error,
          onmessage: (message) => addEvent(JSON.parse(message.data)),
          signal: abortController.signal,
        }
      );

      setLoadingStatus("done");
    } catch (error: any) {
      setLoadingStatus(error as Error);
    }
  };

  const updateEvents = () => {
    if (levents.length >= 1 && events.length >= 1) {
      setLicensePlate(updatedTextLicenceplateEvent(levents[0]));
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    setLoadingStatus("loading");
    loadEvents(abortController);
    return () => abortController.abort();
  }, [context.token]);

  const [pevents, setPEvents] = useState<TextDetectionAndOCREvent[]>([]);
  const [plevents, setPLevents] = useState<TextDetectionAndOCREvent[]>([]);

  if (events !== pevents || levents !== plevents) {
    setPEvents(events);
    setPLevents(levents);
    updateEvents();
  }

  switch (loadingStatus) {
    case "done":
      if (levents.length > 0) {
        return (
          <Box>
            {licensePlate !== null ? (
              <>
                <HelperMessage>License plate found: {licensePlate.inference_result.words.join(", ")}</HelperMessage>
                <InferenceResultView
                  key={licensePlate!.inference_result.inference_id}
                  event={licensePlate!}
                  bottomDivider={false}
                />
              </>
            ) : (
              <HelperMessage>No plate found</HelperMessage>
            )}
          </Box>
        );
      } else {
        return (
          <HelperMessage>
            OCR scans you make will appear here. <br />
            Either use the "Upload Image" button in the top right corner, <br />
            or use the wirevision app to capture images!
          </HelperMessage>
        );
      }

    case "loading":
      return <HelperMessage>Loading...</HelperMessage>;

    default:
      return <HelperMessage>{loadingStatus.message}</HelperMessage>;
  }
}
