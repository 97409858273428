import { useContext, useEffect, useState } from "react";
import { LoadingStatus, TextDetectionAndOCREvent } from "../types";
import { UserContext } from "../lib/UserContext";
import jwtDecode from "jwt-decode";
import { getVINNumberByHeuristic } from "../text/vin";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { loadTextDetectionAndOCREvents } from "../lib/BackendConnection";
import { Box } from "@wiretronic/iris";
import InferenceResultView from "../modules/InferenceResult";
import HelperMessage from "../lib/components/HelperMessage";

export default function VINnumberDetectionPage() {
  const [events, setEvents] = useState<TextDetectionAndOCREvent[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("loading");
  const context = useContext(UserContext);

  const [VINEv, setVINEv] = useState<TextDetectionAndOCREvent | null>(null);

  const eventLimit = 1;

  const updatedTextVINEvent = () => {
    const detections = events[0].inference_result.boxes.map((_, i) => ({
      box: events[0].inference_result.boxes[i],
      word: events[0].inference_result.words[i],
      score: events[0].inference_result.scores[i],
    }));

    const vin = getVINNumberByHeuristic(detections);
    console.log(vin);

    if (vin)
      setVINEv({
        inference_result: { inference_id: "", boxes: [vin.box], words: [vin.word], scores: [vin.score] },
        media: events[0].media,
      });
  };

  const addEvent = (_event: TextDetectionAndOCREvent) =>
    setEvents((_events) => [_event, ..._events].slice(0, eventLimit));

  const loadEvents = async (abortController: AbortController) => {
    if (context.token === null) return;
    const userId = (jwtDecode(context.token) as any)["userId"];

    try {
      const textDetectionEvents = await loadTextDetectionAndOCREvents(userId, context.token, eventLimit);
      setEvents(textDetectionEvents);
      fetchEventSource(
        new URL(
          `/api/v2/user-events/sse?userId=${userId}&origin=wirevision-inference&type=text_detection_and_ocr`,
          process.env.REACT_APP_EVENT_BASE_URL
        ).toString(),
        {
          headers: { Authorization: `Bearer ${context.token}` },
          onerror: console.error,
          onmessage: (message) => addEvent(JSON.parse(message.data)),
          signal: abortController.signal,
        }
      );
      setLoadingStatus("done");
    } catch (error: any) {
      setLoadingStatus(error as Error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    setLoadingStatus("loading");
    loadEvents(abortController);
    return () => abortController.abort();
  }, [context.token]);

  const [pevents, setPEvents] = useState<TextDetectionAndOCREvent[]>([]);

  if (events !== pevents) {
    setPEvents(events);
    if (events.length >= 1) {
      updatedTextVINEvent();
    }
  }

  switch (loadingStatus) {
    case "done":
      if (events.length > 0) {
        return (
          <Box>
            {VINEv !== null ? (
              <>
                <HelperMessage>VIN-Number found: {VINEv.inference_result.words[0]}</HelperMessage>
                <InferenceResultView key={VINEv!.inference_result.inference_id} event={VINEv!} bottomDivider={false} />
              </>
            ) : (
              <HelperMessage>No VIN-number found</HelperMessage>
            )}
          </Box>
        );
      } else {
        return (
          <HelperMessage>
            OCR scans you make will appear here. <br />
            Either use the "Upload Image" button in the top right corner, <br />
            or use the wirevision app to capture images!
          </HelperMessage>
        );
      }

    case "loading":
      return <HelperMessage>Loading...</HelperMessage>;

    default:
      return <HelperMessage>{loadingStatus.message}</HelperMessage>;
  }
}
