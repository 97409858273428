import React, { createContext, useEffect, useState } from "react";
import { PageStateType } from "../types";

export interface UserContextInterface {
  userName: string | null;
  token: string | null;
  pageState: PageStateType;
  setUser: (userName: string, token: string) => void;
  setPageState: (newState: PageStateType) => void;
  logout: () => void;
}

export const UserContext = createContext<UserContextInterface>({
  userName: null,
  token: null,
  pageState: "OCR",
  setUser: () => {},
  setPageState: () => {},
  logout: () => {},
});

export default function UserProvider({ children }: any) {
  const [token, setToken] = useState<string | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [pageState, setPageState] = useState<PageStateType>("OCR");

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    setUserName(localStorage.getItem("userName"));
  }, [token, userName]);

  const setUser = (userName: string, token: string) => {
    setToken(token);
    setUserName(userName);
    localStorage.setItem("token", token);
    localStorage.setItem("userName", userName);
  };

  const logout = () => {
    setToken(null);
    setUserName(userName);
    localStorage.setItem("token", "");
    localStorage.setItem("userName", "");
  };

  return (
    <UserContext.Provider value={{ token, userName, pageState, setUser, setPageState, logout }}>
      {children}
    </UserContext.Provider>
  );
}
