import { Image } from "@wiretronic/iris";

const Footer = () => {
  return (
    <footer
      style={{
        display: "flex",
        flex: 1,
        padding: "2rem 0",
        borderTop: "2px solid #eaeaea",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <a href="https://wirevision.com" target="_blank" rel="noopener noreferrer">
        Powered by{" "}
        <Image
          src={"wirevision_parallel_vector_kit.svg"}
          alt="Wirevision"
          width="100px"
          px={4}
          display={"inline-block"}
          verticalAlign={"bottom"}
        />
      </a>
    </footer>
  );
};

export default Footer;
