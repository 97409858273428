import { DetectionBox, InferenceResult } from "../types";

const getBoxCenter = (bb: DetectionBox): [x: number, y: number] => [
  bb[0] + (bb[2] - bb[0]) / 2,
  bb[1] + (bb[3] - bb[1]) / 2,
];

// Stolen is numeric function
const isNumeric = (str: string): boolean => /^-?\d+$/.test(str);
const onlyText = (str: string): boolean => !/\d/.test(str);

export function getLicensePlate(ocrEvent: InferenceResult, licensePlateBox: DetectionBox): string | undefined {
  const detections = ocrEvent.boxes.map((_, i) => ({
    box: ocrEvent.boxes[i],
    word: ocrEvent.words[i],
    score: ocrEvent.scores[i],
  }));

  const filtered_detections = detections
    .flat(1)
    .filter((d) => {
      const c = getBoxCenter(d.box);
      return (
        licensePlateBox[0] < c[0] && licensePlateBox[2] > c[0] && licensePlateBox[1] < c[1] && licensePlateBox[3] > c[1]
      );
    })
    .map((d) => {
      const _d = d;
      _d.word = d.word.replace(/[^a-zA-Z0-9]/g, "");
      return _d;
    });

  // sort boxes from left to
  filtered_detections.sort((a, b) => a.box[0] - b.box[0]);

  const full_words = filtered_detections.filter((b) => b.word.length === 7);
  let numberWords = filtered_detections.filter((b) => isNumeric(b.word) && b.word.length === 4);

  // No 4 char word, try to concatenate all
  if (numberWords.length === 0 && full_words.length === 0) {
    const newW = filtered_detections
      .filter((b) => isNumeric(b.word))
      .map((b) => b.word)
      .join("")
      .slice(-4);
    if (newW.length !== 4) return undefined;
    numberWords = [
      {
        word: newW,
        box: [0, 0, 0, 0],
        score: 0,
      },
    ];
  }

  const textWords = filtered_detections.filter((b) => onlyText(b.word) && b.word.length === 3);

  for (const det of full_words) {
    const word = det.word;
    const num = word.substring(0, 4);
    const text = word.substring(4, 7);

    if (isNumeric(num)) numberWords.push({ word: num, box: [0, 0, 0, 0], score: 0 });
    if (onlyText(text)) textWords.push({ word: text, box: [0, 0, 0, 0], score: 0 });
  }

  // Last word partial is more likeley to be the correct one (country code is always first)
  if (numberWords.length > 0 && textWords.length > 0)
    return numberWords[numberWords.length - 1].word + " " + textWords[textWords.length - 1].word;
  else return undefined;
}
