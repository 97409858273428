import React, { createRef, useEffect, useState } from "react";
import { DetectionBox, Detection } from "../types";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

export function InferenceImage({ imageURL, detections }: { imageURL: string; detections: Detection[] }) {
  const imageElement = createRef<HTMLImageElement>();
  const [retrivedImgURL, setRetrivedImgURL] = useState<string | null>();
  const [imgSize, setImgSize] = useState<[number, number] | null>();

  const onImageLoad: React.ReactEventHandler<HTMLImageElement> = (_x) => {
    setImgSize([imageElement.current!.naturalWidth, imageElement.current!.naturalHeight]);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    fetch(imageURL, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => res.json())
      .then((url) => {
        setRetrivedImgURL(url);
      });
  }, [imageURL]);

  if (retrivedImgURL == null) return <></>;

  return (
    <div style={{ position: "relative" }}>
      <TransformWrapper centerOnInit>
        <TransformComponent wrapperStyle={{ width: "100%" }} contentStyle={{ width: "100%" }}>
          <img width={"100%"} ref={imageElement} onLoad={onImageLoad} src={retrivedImgURL} alt="inference result"></img>
          {imgSize ? detections.map((d, i) => <TextBox key={i} box={d.box} imgSize={imgSize} text={d.word} />) : <></>}
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
}

function TextBox({ box, text, imgSize }: { box: DetectionBox; text: string; imgSize: [number, number] }) {
  const [left, top, right, bottom] = box;
  const [boxWidth, boxHeight] = [right - left, bottom - top];
  const [imageWidth, imageHeight] = imgSize;

  const [showText, setShowText] = useState(false);
  const [copied, setCopied] = useState(false);

  return (
    <div
      style={{
        position: "absolute",
        top: `${100 * (top / imageHeight)}%`,
        left: `${100 * (left / imageWidth)}%`,
        width: `${100 * (boxWidth / imageWidth)}%`,
        height: `${100 * (boxHeight / imageHeight)}%`,
        border: "1px black solid",
        background: showText ? "rgba(255, 255, 255, 0.8)" : "rgba(255, 255, 255, 0.3)",
        fontSize: 24,
      }}
    >
      <div
        onClick={() => {
          navigator.clipboard.writeText(text);
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        }}
        onMouseEnter={() => setShowText(true)}
        onMouseLeave={() => setShowText(false)}
        style={{ width: "100%", height: "100%", cursor: "pointer" }}
      />
      <span
        style={{
          pointerEvents: "none",
          position: "absolute",
          top: "-1.05em",
          left: "0em",
          right: "0em",
          textAlign: "center",
          lineHeight: 1,
          color: "white",
          transition: "300ms all ease",
          opacity: showText ? 1 : 0,
          textShadow: "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
        }}
      >
        {text}
      </span>
      <div
        style={{
          pointerEvents: "none",
          position: "absolute",
          color: "#4acf69",
          top: copied ? -25 : 0,
          right: boxWidth / 2,
          transition: "400ms all ease",
          opacity: copied ? 1 : 0,
        }}
      >
        Copied!
      </div>
    </div>
  );
}
