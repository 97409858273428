import React, { useContext, useEffect, useState } from "react";
import InferenceResultView from "../modules/InferenceResult";
import { LoadingStatus, TextDetectionAndOCREvent } from "../types";
import jwtDecode from "jwt-decode";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { loadTextDetectionAndOCREvents } from "../lib/BackendConnection";
import { Box } from "@wiretronic/iris";
import { UserContext } from "../lib/UserContext";
import HelperMessage from "../lib/components/HelperMessage";

export default function OCRPage() {
  const [events, setEvents] = useState<TextDetectionAndOCREvent[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("loading");
  const context = useContext(UserContext);

  const eventLimit = 1;

  const addEvent = (event: TextDetectionAndOCREvent) => setEvents((events) => [event, ...events].slice(0, eventLimit));

  useEffect(() => {
    const abortController = new AbortController();
    if (context.token === null) return;

    const userId = (jwtDecode(context.token) as any)["userId"];
    loadTextDetectionAndOCREvents(userId, context.token, eventLimit)
      .then((x) => {
        setEvents(x);
        setLoadingStatus("done");
      })
      .then(() =>
        fetchEventSource(
          new URL(
            `/api/v2/user-events/sse?userId=${userId}&origin=wirevision-inference&type=text_detection_and_ocr`,
            process.env.REACT_APP_EVENT_BASE_URL
          ).toString(),
          {
            headers: { Authorization: `Bearer ${context.token}` },
            onerror: console.error,
            onmessage: (message) => addEvent(JSON.parse(message.data)),
            signal: abortController.signal,
          }
        )
      )
      .catch(setLoadingStatus);
    return () => abortController.abort();
  }, [context.token]);

  switch (loadingStatus) {
    case "done":
      if (events.length > 0) {
        return (
          <Box>
            {events.map((event, i) => (
              <Box key={i}>
                <InferenceResultView
                  key={event.inference_result.inference_id}
                  event={event}
                  bottomDivider={i < events.length - 1}
                />
              </Box>
            ))}
          </Box>
        );
      } else {
        return (
          <HelperMessage>
            OCR scans you make will appear here. <br />
            Either use the "Upload Image" button in the top right corner, <br />
            or use the wirevision app to capture images!
          </HelperMessage>
        );
      }

    case "loading":
      return <HelperMessage>Loading...</HelperMessage>;

    default:
      return <HelperMessage>{loadingStatus.message}</HelperMessage>;
  }
}
