import { Detection } from "../types";

export function getVINNumberByHeuristic(detections: Detection[]): Detection | undefined {
  // A VIN number is a 17 alphanumeric string.
  // It does not contain the characters O,I,Q (to avoid confusion with 0, 1 and 9)
  const candidate = detections
    .filter((x) => x.word.length === 17)
    .filter((x) => x.word.split("").some((y) => y >= "0" && y <= "9"))[0];

  if (candidate) {
    candidate.word = candidate.word.replaceAll("O", "0");
    candidate.word = candidate.word.replaceAll("I", "1");
    candidate.word = candidate.word.replaceAll("Q", "9");
  }
  return candidate;
}
