import { TextDetectionAndOCREvent } from "../types";

export async function login(email: string, password: string): Promise<{ token: string; userName: string }> {
  const url = new URL("/api/login", process.env.REACT_APP_AUTH_BASE_URL);
  const response = await fetch(url.toString(), {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  });

  if (response.status !== 200) throw new Error(response.statusText);
  return (await response.json()) as { token: string; userName: string };
}

export async function loadTextDetectionAndOCREvents(
  userId: string,
  token: string,
  limit: number
): Promise<TextDetectionAndOCREvent[]> {
  const url = new URL(
    `/api/v2/user-events?userId=${userId}&origin=wirevision-inference&type=text_detection_and_ocr&limit=${limit}`,
    process.env.REACT_APP_EVENT_BASE_URL
  );
  const res = await fetch(url.toString(), {
    headers: { Authorization: `Bearer ${token}` },
  });

  if (!res.ok) throw new Error("Could not fetch inference results");

  const events = (await res.json()) as any[];
  if (events.length === 0) throw new Error("No inference results found");
  return events;
}

export function remapLicensePlateEventToOCREventInferenceResults(events: any): TextDetectionAndOCREvent[] {
  if (events.length === 0) throw new Error("No inference results found");
  events.map((e: any) => {
    const tempe = { boxes: [], words: [], scores: [] };
    tempe.boxes = e["inference_result"]["detections"].map((b: any) => b["box"]);
    tempe.words = e["inference_result"]["detections"].map((b: any) => b["label"]);
    tempe.scores = e["inference_result"]["detections"].map((b: any) => b["score"]);

    e["inference_result"] = tempe;
  });
  return events;
}

export async function loadLicensePlateEvent(
  userId: string,
  token: string,
  limit: number
): Promise<TextDetectionAndOCREvent[]> {
  const url = new URL(
    `/api/v2/user-events?userId=${userId}&origin=wirevision-inference&type=license-plate&limit=${limit}`,
    process.env.REACT_APP_EVENT_BASE_URL
  );
  const res = await fetch(url.toString(), {
    headers: { Authorization: `Bearer ${token}` },
  });

  if (!res.ok) throw new Error("Could not fetch inference results");

  const events = (await res.json()) as any[];

  return remapLicensePlateEventToOCREventInferenceResults(events);
}

export function sendToInferenceLicensePlate(formData: FormData, token: string) {
  const urlLicensePlate = new URL("/v3/license-plate", process.env.REACT_APP_INFERENCE_BASE_URL);

  fetch(urlLicensePlate.toString(), {
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    body: formData,
  });
}

export function sendToInferenceTextDetection(formData: FormData, token: string) {
  const url = new URL("/v3/text-detection-and-ocr", process.env.REACT_APP_INFERENCE_BASE_URL);

  fetch(url.toString(), {
    headers: { Authorization: `Bearer ${token}` },
    method: "POST",
    body: formData,
  });
}
