import React, { useContext, useRef, useState } from "react";
import "./App.css";
import UserProvider, { UserContext } from "./lib/UserContext";
import { Box, Button, ButtonGroup, Header, IrisProvider, Login, Page } from "@wiretronic/iris";
import { login, sendToInferenceLicensePlate, sendToInferenceTextDetection } from "./lib/BackendConnection";
import { IoLogOutOutline } from "react-icons/io5";
import Footer from "./lib/components/Footer";
import OCRPage from "./pages/TextDetectionAndOCRPage";
import LicenseplatePage from "./pages/TextRecognitionAndLicenseplatePage";
import { PageState } from "./types";
import VINnumberDetectionPage from "./pages/VINnumberDetectionPage";

function LoginProvider({ children }: { children: any }) {
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(UserContext);

  const handleSubmit = async (email: string, password: string) => {
    setIsLoading(true);
    login(email, password)
      .then(({ token, userName }) => {
        context.setUser(userName, token);
      })
      .catch(() => setError("Incorrect email & password combination"))
      .finally(() => setIsLoading(false));
  };

  if (context.token) {
    return children;
  }

  return <Login onSubmit={handleSubmit} error={error} isLoading={isLoading} />;
}

function PageContent() {
  const context = useContext(UserContext);
  let content = <></>;

  switch (context.pageState) {
    case "OCR":
      content = <OCRPage />;
      break;
    case "LicensePlate":
      content = <LicenseplatePage />;
      break;

    case "VIN-number":
      content = <VINnumberDetectionPage />;
      break;
  }
  return (
    <Box h={"100%"} overflow={"auto"} background={"#fff"} p={16}>
      <Header>{context.pageState}</Header>
      <Box pb={8} />
      {content}
      <Footer />
    </Box>
  );
}

const MenuWidget = () => {
  const context = useContext(UserContext);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const fileChange = () => {
    const fileInput = fileInputRef.current;
    if (!fileInput?.files?.length || context.token === null) return;

    const data = new FormData();
    data.append("imagefile", fileInput.files[0]);
    sendToInferenceLicensePlate(data, context.token);
    sendToInferenceTextDetection(data, context.token);
  };

  return (
    <>
      <ButtonGroup>
        {PageState.map((state) => (
          <Button
            key={state}
            variant={"tertiary"}
            onClick={() => {
              context.setPageState(state);
            }}
          >
            {state}
          </Button>
        ))}
      </ButtonGroup>
      <ButtonGroup>
        <Button onClick={() => fileInputRef.current?.click()} variant="outline">
          Upload image
        </Button>
        <input ref={fileInputRef} type="file" hidden onChange={fileChange} />
        <Button
          rightIcon={<IoLogOutOutline />}
          onClick={() => {
            context.logout();
          }}
          variant="tertiary"
        >
          Sign out
        </Button>
      </ButtonGroup>
    </>
  );
};

const PageHeader = () => (
  <Header>
    <div>Wirevision OCR tech demo interface</div>

    <MenuWidget />
  </Header>
);

function App() {
  return (
    <IrisProvider>
      <UserProvider>
        <LoginProvider>
          <Box bg="gray.100">
            <Box maxW={2000} mx="auto">
              <Page Header={<PageHeader />} Content={<PageContent />} Sidebar={<></>} />
            </Box>
          </Box>
        </LoginProvider>
      </UserProvider>
    </IrisProvider>
  );
}

export default App;
